import { SVGProps, Ref, forwardRef } from 'react'

const SvgArrowLeft = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    width="1.5rem"
    height="1.5rem"
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path stroke="currentColor" strokeWidth={2} d="M9.15 4.35 2 11.5l7.15 7.15M2 11.5h19" />
  </svg>
)

const ForwardRef = forwardRef(SvgArrowLeft)
export default ForwardRef
